import React, { Component } from "react";
import LazyLoad from 'react-lazyload';
import InfiniteScroll from "react-infinite-scroll-component";
import './listing.css';



class ListingLoader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            skip: 0,
            limit: 10,
            listings: [],
            loading: true,
            hasMore: true
        }
    }

    fetchMoreData = () => {
        if (this.state.listings.length >= 70) {
            this.setState({ hasMore: false });
            return;
        }
       
        // a fake async api call like which sends
        // 20 more records in .5 secs
        this.getListing();
    };


    componentDidMount() {
        this.getListing();
    }

    getListing = () => {

        const requestBody = {
            query: `
                {
                carListingCollection(order: pipedriveDealId_DESC, limit: 10, skip: ${this.state.skip}) {
                    items {
                        carName
                        price
                        location
                        pipedriveDealId
                        listingIndexImage {
                            url
                        }
                    }
                }
                }
    `};

        //fetch("http://172.16.16.210:8081/website/contentful", {
        fetch("https://carxapi.com/website/contentful", {

            method: "POST",
            body: JSON.stringify(requestBody),
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(res => {
                if (res.status !== 200 && res.status !== 201) {
                    throw new Error("Failed!");
                }
                return res.json();
            })
            .then(resData => {
                if (resData.data) {
                    let currentListing = this.state.listings;
                    let newListings = currentListing.concat(resData.data.carListingCollection.items);
                    let skip = this.state.skip + this.state.limit;

                    this.setState({ listings: newListings, skip: skip });
                }
            })
            .catch(err => {
                this.setState({ message: "Error:" + err });
                console.log(err);
            });


    }

    render() {

        let carListing = <p>loading</p>;

        if (this.state.listings) {
            carListing = this.state.listings.map((item) => {
                console.log(item.listingIndexImage);
                return (
                    <LazyLoad height={370} key={item.pipedriveDealId} offset={705}>

                        <div className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                <div
                                    style={{
                                        backgroundImage: `url(${item.listingIndexImage.url + '?fit=thumb&f=center&w=500&h=350'})`,
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center center",
                                        width: "100%",
                                        height: "350px",
                                        color: "#f5f5f5",
                                        margin: "0px",
                                        padding: "0px",
                                        borderRadius: "10px",
                                    }}
                                >
             
                                </div>

                                </div>
                                <div className="flip-card-back">
                                    <p>
                                        <strong>{item.carName}</strong><br />
                                        {item.price}<br />
                                        {item.location}<br />
                                    </p>
                                </div>
                            </div>
                        </div>

                    </LazyLoad>


                )
            });

        }


        return (
            <>

                <InfiniteScroll
                    dataLength={this.state.listings.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.hasMore}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>You've run out of road</b>
                        </p>
                    }
                >
                    {carListing}
                </InfiniteScroll>

            </>

        );
    }

}

export default ListingLoader;




  /*
<div className="flip-card">
                            <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <img src={item.listingIndexImage.url + '?fit=thumb&f=center&w=500&h=350'} style={{ width: null, height: "100%", flex: 1,  justifyContent: 'center' }} />
                                </div>
                                <div className="flip-card-back">
                                    <p>
                                        <strong>{item.carName}</strong><br />
                                        {item.price}<br />
                                        {item.location}<br />
                                    </p>
                                </div>
                            </div>
                        </div>



  */