import React, { Component } from "react";
import ListingLoader from './components/Listings/ListingsLoader';

import './App.css';

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {

    return (<div className="App">
      <header className="App-header">

       
      <h1>cardy</h1>
      </header>
      <main className="App-main">
        <ListingLoader />
      </main>
     
    </div>);
  }

}

export default App;
